import "./App.css";
import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import MainLayout from "./components/MainLayout";
import Authentication from "./components/Authentication";
import Authorization from "./components/Authorization";
import PageNotFound from "./components/PageNotFound";

/***************Admin *************/
import AdminDashboard from "./components/AdminDashboard";
import AdminLogin from "./components/AdminLogin";
import UserTable from "./components/config/user/UserTable";
import CustomerTable from "./components/config/customer/CustomerTable";
import VendorTable from "./components/config/vendor/VendorTable";
import SupervisorRATable from "./components/config/mappings/supervisor-ra/SupervisorRATable";
import MisSupervisorTable from "./components/config/mappings/mis-supervisor/MisSupervisorTable";
import RegionalSupervisorTable from "./components/config/mappings/regional-supervisor/RegionalSupervisorTable";
import NationalRegionalTable from "./components/config/mappings/national-regional/NationalRegionalTable";
import SiteTable from "./components/config/sites/SiteTable";
import RASitesTable from "./components/config/mappings/ra-sites/RASitesTable";
import UpdateSiteVisitForm from "./components/sites/admin/UpdateSiteVisitForm";
import VisitFormTable from "./components/sites/admin/VisitFormTable";
import QRTFormTable from "./components/sites/admin/QRTFormTable";
import UpdateQRTForm from "./components/sites/admin/UpdateQRTForm";
import TakeoverFormTable from "./components/sites/admin/TakeoverFormTable";
import UpdateTakeoverForm from "./components/sites/admin/UpdateTakeoverForm";
import RewardsStatement from "./components/reports/RewardsStatement";
import SummaryReport from "./components/reports/SummaryReport";
import MainReport from "./components/reports/MainReport";
import MaterialRequirementTable from "./components/materialRequirement/MaterialRequirementTable";

/**************MIS  ******************/
import MISLogin from "./components/MISLogin";
import MISDashboard from "./components/MISDashboard";
import MISAddSiteVisitForm from "./components/sites/mis/MISAddSiteVisitForm";
import MISAddQRTForm from "./components/sites/mis/MISAddQRTForm";
import MISUpdateSiteVisitForm from "./components/sites/mis/MISUpdateSiteVisitForm";
import MISVisitFormTable from "./components/sites/mis/MISVisitFormTable";
import MISQRTFormTable from "./components/sites/mis/MISQRTFormTable";
import MISUpdateQRTForm from "./components/sites/mis/MISUpdateQRTForm";
import MISTakeoverFormTable from "./components/sites/mis/MISTakeoverFormTable";
import MISUpdateTakeoverForm from "./components/sites/mis/MISUpdateTakeoverForm";
import MISAddTakeoverForm from "./components/sites/mis/MISAddTakeoverForm";
import PrivacyPolicy from "./components/common/public/PrivacyPolicy";
import ShutterActivityTable from "./components/sites/admin/ShutterActivityTable";
import MISShutterActivityTable from "./components/sites/mis/MISShutterActivityTable";
import FireIncidentTable from "./components/sites/admin/FireIncidentTable";
import FireIncidentDetails from "./components/sites/admin/FireIncidentDetails";
import MISFireIncidentTable from "./components/sites/mis/MISFireIncidentTable";
import MISFireIncidentDetails from "./components/sites/mis/MISFireIncidentDetails";
import SupervisorVisitFormTable from "./components/sites/admin/SupervisorVisitFormTable";
import MISSupervisorVisitFormTable from "./components/sites/mis/MISSupervisorVisitFormTable";
import SupervisorVisitFormDetails from "./components/sites/admin/SupervisorVisitFormDetails";
import MISSupervisorVisitFormDetails from "./components/sites/mis/MISSupervisorVisitFormDetails";
import MaterialRequirementReport from "./components/reports/MaterialRequirementReport";
import SupervisorVisitFormReport from "./components/reports/SupervisorVisitFormReport";
import ACNonFunctionalReport from "./components/reports/ACNonFunctionalReport";
import SignageNonFunctionalReport from "./components/reports/SignageNonFunctionalReport";
import LollipopNonFunctionalReport from "./components/reports/LollipopNonFunctionalReport";
import TheftFireReport from "./components/reports/TheftFireReport";
import ShutterActivityReport from "./components/reports/ShutterActivityReport";
import MissedVisitReport from "./components/reports/MissedVisitReport";
import MainReportDateRange from "./components/reports/MainReportDateRange";
import FormImagesView from "./components/common/public/FormImagesView";
import SiteActivateDeactivateReport from "./components/reports/SiteActivateDeactivateReport";
import UserActivateDeactivateReport from "./components/reports/UserActivateDeactivateReport";
import DeepCleaningReport from "./components/reports/DeepCleaningReport";
import RemainingVisitReport from "./components/reports/RemainingVisitReport";
import HotoReport from "./components/reports/HotoReport";
import TempData from "./components/common/public/TempData";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<>Home </>}></Route>
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/mis/login" element={<MISLogin />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route path="/form-images/:formId" element={<FormImagesView />} />
      <Route path="/supervisor-data" element={<TempData />} />
      <Route element={<Authentication />}>
        <Route path="portal" element={<MainLayout />}>
          <Route element={<Authorization allowedRoles={["admin"]} />}>
            {/* allowedRoles is array */}
            {/* all admin only routes here */}
            <Route
              path="/portal/admin/dashboard"
              element={<AdminDashboard />}
            />
            <Route path="/portal/admin/user" element={<UserTable />} />
            <Route path="/portal/admin/customer" element={<CustomerTable />} />
            <Route path="/portal/admin/vendor" element={<VendorTable />} />
            <Route
              path="/portal/admin/supervisor-ra"
              element={<SupervisorRATable />}
            />
            <Route
              path="/portal/admin/mis-supervisor"
              element={<MisSupervisorTable />}
            />
            <Route
              path="/portal/admin/regional-supervisor"
              element={<RegionalSupervisorTable />}
            />
            <Route
              path="/portal/admin/national-regional"
              element={<NationalRegionalTable />}
            />
            <Route path="/portal/admin/sites" element={<SiteTable />} />
            <Route path="/portal/admin/ra-sites" element={<RASitesTable />} />
            <Route
              path="/portal/admin/site/visit-forms/:visitFormId"
              element={<UpdateSiteVisitForm />}
            />
            <Route
              path="/portal/admin/site/visit-forms"
              element={<VisitFormTable />}
            />
            <Route
              path="/portal/admin/site/qrt-forms"
              element={<QRTFormTable />}
            />
            <Route
              path="/portal/admin/site/qrt-forms/:qrtFormId"
              element={<UpdateQRTForm />}
            />
            <Route
              path="/portal/admin/site/takeover-forms"
              element={<TakeoverFormTable />}
            />
            <Route
              path="/portal/admin/site/takeover-forms/:takeoverFormId"
              element={<UpdateTakeoverForm />}
            />
            <Route
              path="/portal/admin/reports/rewards-statement"
              element={<RewardsStatement />}
            />
            <Route
              path="/portal/admin/reports/summary-report"
              element={<SummaryReport />}
            />
            <Route
              path="/portal/admin/reports/main-report"
              element={<MainReport />}
            />
            <Route
              path="/portal/admin/reports/material-requirements"
              element={<MaterialRequirementReport />}
            />
            <Route
              path="/portal/admin/reports/supervisor-visit-form"
              element={<SupervisorVisitFormReport />}
            />
            <Route
              path="/portal/admin/reports/ac-non-functional"
              element={<ACNonFunctionalReport />}
            />
            <Route
              path="/portal/admin/reports/signage-non-functional"
              element={<SignageNonFunctionalReport />}
            />
            <Route
              path="/portal/admin/reports/lollipop-non-functional"
              element={<LollipopNonFunctionalReport />}
            />
            <Route
              path="/portal/admin/reports/theft-fire"
              element={<TheftFireReport />}
            />
            <Route
              path="/portal/admin/reports/shutter-activity-report"
              element={<ShutterActivityReport />}
            />
            <Route
              path="/portal/admin/reports/missed-visits"
              element={<MissedVisitReport />}
            />
            <Route
              path="/portal/admin/reports/main-report-date-range"
              element={<MainReportDateRange />}
            />
            <Route
              path="/portal/admin/reports/takeover"
              element={<HotoReport />}
            />
            <Route
              path="/portal/admin/reports/site-activate-deactivate"
              element={<SiteActivateDeactivateReport />}
            />
            <Route
              path="/portal/admin/reports/user-activate-deactivate"
              element={<UserActivateDeactivateReport />}
            />
            <Route
              path="/portal/admin/reports/deep-cleaning"
              element={<DeepCleaningReport />}
            />
            <Route
              path="/portal/admin/reports/remaining-visits"
              element={<RemainingVisitReport />}
            />
            <Route
              path="/portal/admin/material-requirement"
              element={<MaterialRequirementTable />}
            />
            <Route
              path="/portal/admin/shutter-activity"
              element={<ShutterActivityTable />}
            />

            <Route
              path="/portal/admin/fire-incidents"
              element={<FireIncidentTable />}
            />
            <Route
              path="/portal/admin/fire-incidents/:fireFormId"
              element={<FireIncidentDetails />}
            />
            <Route
              path="/portal/admin/supervisor-visit-forms"
              element={<SupervisorVisitFormTable />}
            />
            <Route
              path="/portal/admin/supervisor-visit-forms/:formId"
              element={<SupervisorVisitFormDetails />}
            />
          </Route>
          {/* add different combinations of allowedroles here*/}
          <Route element={<Authorization allowedRoles={["mis"]} />}>
            {/* allowedRoles is array */}
            {/* all mis only routes here */}
            <Route path="/portal/mis/dashboard" element={<MISDashboard />} />
            <Route
              path="/portal/mis/site/visit-forms/add"
              element={<MISAddSiteVisitForm />}
            />
            <Route
              path="/portal/mis/site/visit-forms/:visitFormId"
              element={<MISUpdateSiteVisitForm />}
            />
            <Route
              path="/portal/mis/site/visit-forms"
              element={<MISVisitFormTable />}
            />
            <Route
              path="/portal/mis/site/qrt-forms"
              element={<MISQRTFormTable />}
            />
            <Route
              path="/portal/mis/site/qrt-forms/add"
              element={<MISAddQRTForm />}
            />
            <Route
              path="/portal/mis/site/qrt-forms/:qrtFormId"
              element={<MISUpdateQRTForm />}
            />
            <Route
              path="/portal/mis/site/takeover-forms"
              element={<MISTakeoverFormTable />}
            />
            <Route
              path="/portal/mis/site/takeover-forms/add"
              element={<MISAddTakeoverForm />}
            />
            <Route
              path="/portal/mis/site/takeover-forms/:takeoverFormId"
              element={<MISUpdateTakeoverForm />}
            />
            <Route
              path="/portal/mis/shutter-activity"
              element={<MISShutterActivityTable />}
            />
            <Route
              path="/portal/mis/fire-incidents"
              element={<MISFireIncidentTable />}
            />
            <Route
              path="/portal/mis/fire-incidents/:fireFormId"
              element={<MISFireIncidentDetails />}
            />
            <Route
              path="/portal/mis/supervisor-visit-forms"
              element={<MISSupervisorVisitFormTable />}
            />
            <Route
              path="/portal/mis/supervisor-visit-forms/:formId"
              element={<MISSupervisorVisitFormDetails />}
            />
          </Route>
        </Route>
        <Route path="unauthorized" element={<>Unauthorized</>} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </>
  )
);

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerBg: "rgb(20, 168, 0)",
            colorTextHeading: "rgb(255, 255, 255)",
          },
        },
        token: {
          algorithm: "compactAlgorithm",
          // Seed Token
          colorPrimary: "#1677ff",
          borderRadius: 11,
          fontFamily: "Arial",
          colorTextBase: "#455560",
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
