import React, { useEffect } from "react";
import { Modal, Form, Col, Row, message, Select } from "antd";
import { API_METHODS, BLANK_VALUE } from "../../../../constants";
import useApi from "../../../../hooks/useApi";
import { getClientConfig } from "../../../../generalFunctions";

function AddNationalToRegional(props) {
  const [form] = Form.useForm();
  const { visible, close } = props;

  const { regional, national } = getClientConfig();

  const [response, request] = useApi(
    "/config/national-regional",
    null,
    API_METHODS.POST
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success(
        "National Head Regional Head relation added successfully."
      );
    }
  }, [response]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        request(values, API_METHODS.POST);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={"Add National Head to Regional Head's"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="nationalId"
              label="National Head"
              rules={[
                { required: true, message: "National Head is required." },
              ]}
            >
              <Select
                placeholder="Select National Head"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {national !== BLANK_VALUE &&
                  national?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.displayName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="regionalIds"
              label="Regional Head"
              rules={[
                { required: true, message: "Regional Head is required." },
              ]}
            >
              <Select
                placeholder="Select Regional Head"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                mode="multiple"
              >
                {regional !== BLANK_VALUE &&
                  regional?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.displayName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddNationalToRegional;
