import React, { useEffect } from "react";
import { Modal, Form, Col, Row, message, Select } from "antd";
import { API_METHODS, BLANK_VALUE } from "../../../../constants";
import useApi from "../../../../hooks/useApi";
import { getClientConfig } from "../../../../generalFunctions";

function AddRegionalToSupervisor(props) {
  const [form] = Form.useForm();
  const { visible, close } = props;

  const { supervisor, regional } = getClientConfig();

  const [response, request] = useApi(
    "/config/regional-supervisor",
    null,
    API_METHODS.POST
  );

  useEffect(() => {
    if (response.data) {
      close(true);
      message.success("Regional head Supervisor relation added successfully.");
    }
  }, [response]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        request(values, API_METHODS.POST);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={"Add Regional to Supervisor's"}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      okText="Save"
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="regionalId"
              label="Regional Head"
              rules={[
                { required: true, message: "Regional Head is required." },
              ]}
            >
              <Select
                placeholder="Select Regional Head"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {regional !== BLANK_VALUE &&
                  regional?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.displayName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="supervisorIds"
              label="Supervisor"
              rules={[{ required: true, message: "Supervisor is required." }]}
            >
              <Select
                placeholder="Select Supervisor"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                mode="multiple"
              >
                {supervisor.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddRegionalToSupervisor;
