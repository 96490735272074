import { Card, Col, Form, Row, Button, Space, Select } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import { replaceEmpty } from "../../generalFunctions";
import dayjs from "dayjs";

function SignageNonFunctionalReport() {
  const columns = [
    {
      title: "Site ID",
      dataIndex: "site_id",
    },
    {
      title: "MSP",
      dataIndex: "msp",
    },
    {
      title: "RA",
      dataIndex: "ra_name",
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/signage-off",
    null,
    API_METHODS.GET
  );

  const [tableData, setTableData] = useState();
  let reportHeaders = [["Site Id", "Site Type", "ATM Id", "MSP", "RA Name","City","State","Address","Signage Working","Signange Ageing"]];
  const [form] = Form.useForm();
  const [getCityResponse, getCityRequest] = useApi("/admin/reports/city", null, API_METHODS.GET);
  const [getStateResponse, getStateRequest] = useApi("/admin/reports/state", null, API_METHODS.GET);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    if (data?.length > 0) {
      data = replaceEmpty(data);
    }
    setTableData(data);
  }, [responseData]);

  useEffect(() => {
    getRequest();
    getCityRequest();
    getStateRequest();
  }, []);
  
    useEffect(() => {
        const data =
        getCityResponse.data !== BLANK_VALUE && getCityResponse.data !=null ? getCityResponse.data : [];
        setCity(data);
    }, [getCityResponse]);
  
    useEffect(() => {
        const data =
        getStateResponse.data !== BLANK_VALUE &&  getStateResponse.data !=null ? getStateResponse.data : [];
        setState(data);
    }, [getStateResponse]);
  
    
    const getReportWithFilters = () => {
      form.validateFields().then(values => {
          let path = "/admin/reports/signage-off";
          let queryParams = [];
          if (values.city) {
            queryParams.push(`city=${values.city}`);
          }
          if (values.state) {
            queryParams.push(`state=${values.state}`);
          }
          if (queryParams.length > 0) {
            path += `?${queryParams.join('&')}`;
          }
          getRequest(null, API_METHODS.GET, path);
      }).catch(() => { });
    }

  return (
    <Card title="Signage Non Functional">      
    <Form form={form}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="city" label={<strong>City</strong>}>
              <Select
                  placeholder="Select City"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                  }
              >
                  {city.map((option) => (
                  <Select.Option key={option.city} value={option.city}>
                      {option.city}
                  </Select.Option>
                  ))}
              </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name="state" label={<strong>State</strong>}>
                <Select
                    placeholder="Select State"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                    }
                >
                    {state.map((option) => (
                    <Select.Option key={option.state} value={option.state}>
                        {option.state}
                    </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>                    
        <Col span={4}>
            <Space>
                <Button type="primary" onClick={getReportWithFilters}>Search</Button>
                <Button type="primary" onClick={() => { form.resetFields(); }}>Reset</Button>
            </Space>
        </Col>
      </Row>
    </Form>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={
          "Signage-Non-Functional-" + dayjs().format("DD-MM-YYYY")
        }
      />
    </Card>
  );
}

export default SignageNonFunctionalReport;
