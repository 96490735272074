import { Button, Card, Col, DatePicker, Form, Row, Space, Select } from "antd";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { API_METHODS, BLANK_VALUE } from "../../constants";
import TableComponent from "../common/table/TableComponent";
import dayjs from "dayjs";
import { disableFutureDates, replaceEmpty } from "../../generalFunctions";

function SiteActivateDeactivateReport() {
  const columns = [
    {
      title: "Site Id",
      dataIndex: "site_id",
    },
    {
      title: "Atm Id",
      dataIndex: "atm_id",
    },
    {
      title: "MSP",
      dataIndex: "msp",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
    },
    {
      title: "Reactivation Date",
      dataIndex: "reactivation_date",
    },
    {
      title: "Deactivation Date",
      dataIndex: "deactivation_date",
    },
    {
      title: "Site Activate/Deactivate",
      dataIndex: "status",
    },
  ];

  const [responseData, getRequest] = useApi(
    "/admin/reports/site-activate-deactivate",
    null,
    API_METHODS.POST
  );
  const [tableData, setTableData] = useState();
  const [form] = Form.useForm();
  let reportHeaders = [
    [
      "Site Id",
      "ATM Id",
      "MSP",
      "Address",
      "Activation Date",
      "Reactivation Date",
      "Deactivation Date",
      "Site Activate/Deactivate	",
    ],
  ];

  const [getCityResponse, getCityRequest] = useApi(
    "/admin/reports/city",
    null,
    API_METHODS.GET
  );
  const [getStateResponse, getStateRequest] = useApi(
    "/admin/reports/state",
    null,
    API_METHODS.GET
  );
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    let data = responseData.data !== BLANK_VALUE ? responseData.data : [];
    data = replaceEmpty(data);
    setTableData(data);
  }, [responseData]);

  useEffect(() => {
    getCityRequest();
    getStateRequest();
  }, []);

  useEffect(() => {
    const data =
      getCityResponse.data !== BLANK_VALUE && getCityResponse.data != null
        ? getCityResponse.data
        : [];
    setCity(data);
  }, [getCityResponse]);

  useEffect(() => {
    const data =
      getStateResponse.data !== BLANK_VALUE && getStateResponse.data != null
        ? getStateResponse.data
        : [];
    setState(data);
  }, [getStateResponse]);

  const getForms = () => {
    form
      .validateFields()
      .then((values) => {
        let requestBody = {};
        requestBody.month = dayjs(values.date).month() + 1;
        requestBody.year = dayjs(values.date).year();
        requestBody.city = values.city ? values.city : undefined;
        requestBody.state = values.state ? values.state : undefined;
        getRequest(requestBody);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card title="Site Activation Report">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="date"
              label={<strong>For Month</strong>}
              rules={[{ required: true }]}
            >
              <DatePicker
                allowClear
                picker="month"
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="city" label={<strong>City</strong>}>
              <Select
                placeholder="Select City"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {city.map((option) => (
                  <Select.Option key={option.city} value={option.city}>
                    {option.city}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="state" label={<strong>State</strong>}>
              <Select
                placeholder="Select State"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {state.map((option) => (
                  <Select.Option key={option.state} value={option.state}>
                    {option.state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" onClick={getForms}>
                Search
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                }}
              >
                Reset
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <TableComponent
        columns={columns}
        tableData={tableData}
        loading={responseData.isLoading}
        clientSideRender={true}
        showDownloadOption={true}
        reportHeaders={reportHeaders}
        reportData={tableData}
        exportFileName={
          "SiteActivation-" + form.getFieldValue("date")?.format("MM-YYYY")
        }
      />
    </Card>
  );
}

export default SiteActivateDeactivateReport;
